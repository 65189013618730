import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, render, ...otherProps }) => {
    return (
        <Route
            {...otherProps}
            render={(props) =>
                window._user ? (
                    render ? (
                        render(props)
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <Redirect to={'/login?redirect=' + window.location.pathname} />
                )
            }
        />
    )
}
