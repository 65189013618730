export const S3_PUBLIC = () =>
    (window && window._appConfig && window._appConfig.S3_PUBLIC) ||
    'https://s3-eu-west-1.amazonaws.com/static-test.trustoo.nl/'

export const S3_PRIVATE = () =>
    (window && window._appConfig && window._appConfig.S3_PRIVATE) || 'https://private.trustoo.nl/'

export const STATUS_MAP = {
    1: 'Bouwvergunning verleend',
    2: 'Niet gerealiseerd pand',
    3: 'Bouw gestart',
    4: 'Pand in gebruik (niet ingemeten)',
    5: 'Pand in gebruik',
    6: 'Verbouwing pand',
    7: 'Sloopvergunning verleend',
    8: 'Pand gesloopt',
    9: 'Pand buiten gebruik',
    10: 'Pand ten onrechte opgevoerd',
}

export const OBJECT_TYPE_MAP = {
    1: 'Verblijfsobject',
    2: 'Standplaats',
    3: 'Ligplaats',
}

export const ALLOWED_MIMES = () =>
    (window && window._appConfig && window._appConfig.ALLOWED_MIMES) || {}

/**
 * MAX_FILE_SIZE (10MB)
 * - lower than the real upload limit in the backend (upload_max_filesize) so it won't trigger backend exceptions
 */
export const MAX_FILE_SIZE = 10 //MB,

/**
 * MAX_POST_SIZE (15MB)
 * - lower than the real post limit in the backend (post_max_size)
 * - also its needs to be lower than the sending and inbound size limits of email clients
 */
export const MAX_POST_SIZE = 15 //MB

export const PROFILE_STATUS_UNTOUCHED = 2
export const PROFILE_STATUS_ENABLED = 1
export const PROFILE_STATUS_DISABLED = 0
export const PROFILE_STATUS_ON_HOLD = 3
export const PROFILE_STATUS_GDPR = 4
export const PROFILE_STATUS_DISABLED_BAD_PROFILE = 5
export const PROFILE_STATUS_GHOST = 6
export const PROFILE_STATUS_NEW_IMPORT = 7
export const PROFILE_STATUS_CHURNED = 8

export const PROFILE_STATUSES_DISABLED = [
    PROFILE_STATUS_DISABLED,
    PROFILE_STATUS_GDPR,
    PROFILE_STATUS_DISABLED_BAD_PROFILE,
    PROFILE_STATUS_CHURNED,
]

export const WEBSITE_REGEX =
    /^((https?|ftp|smtp):\/\/)?(www\.)?[-a-z.0-9]+\.[a-z]{2,}(\/[-a-zA-Z0-9#.?&]+\/?)*($|\/)/

export const REASON_DOUBLE_MATCH = 1
export const REASON_NOT_MY_REGION = 2
export const REASON_NOT_MY_SERVICE = 3
export const REASON_NO_CUSTOMER = 5
export const REASON_NO_CONTACT = 7
export const REASON_PHONE_NOT_IN_USE = 8
export const REASON_NOT_SERIOUSLY_LOOKING = 9
export const REASON_NOT_AVAILABLE_ON_DATE = 10
