import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { SelectCheckbox } from '@advanza/input'
import { Divider, Icon, LinearLoader, LoadingDots, MaxWidth } from '@advanza/ui'
import Page from 'components/ui/Page'
import { getDate } from 'date'
import formatDistance from 'date-fns/formatDistance'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import style from './HomePageTestimonialsPage.module.css'

const TESTIMONIAL_SOURCE_GOOGLE = 1
const TESTIMONIAL_SOURCE_KLANTENVERTELLEN = 2
const TESTIMONIAL_SOURCE_TRUSTPILOT = 3

const TESTIMONIAL_SOURCES = {
    [TESTIMONIAL_SOURCE_GOOGLE]: 'Google',
    [TESTIMONIAL_SOURCE_KLANTENVERTELLEN]: 'KlantenVertellen',
    [TESTIMONIAL_SOURCE_TRUSTPILOT]: 'Trustpilot',
}

const getUniqueId = (review) => `${review.testimonial_source_id}#${review.external_id}`

const useHomepageTestimonials = () => {
    const countryCode = useSelector((state) => state.countries.countries[0]) || 'NL'
    const [sourceIds, setSourceIds] = useState([
        TESTIMONIAL_SOURCE_KLANTENVERTELLEN,
        TESTIMONIAL_SOURCE_TRUSTPILOT,
    ])

    const [data, setData] = useState({})

    const reviews = data.reviews || []

    const selected = data.selected || {}
    const setSelected = (review, isSelected) =>
        setData({ ...data, selected: { ...data.selected, [getUniqueId(review)]: isSelected } })

    const inviteInfos = data.inviteInfos || []

    const [isLoading, setIsLoading] = useState(false)
    const [loadedFor, setLoadedFor] = useState('')
    const desiredLoadedFor = countryCode + sourceIds.join()

    useEffect(() => {
        if (!isLoading && loadedFor !== desiredLoadedFor) {
            setIsLoading(true)
            call('office/testimonials/get-home-page', {
                query: { countryCode, sourceIds },
            })
                .then(setData)
                .finally(() => {
                    setLoadedFor(desiredLoadedFor)
                    setIsLoading(false)
                })
        }
    }, [countryCode, desiredLoadedFor, isLoading, loadedFor, sourceIds])

    const toggleReview = (review) => {
        setIsLoading(getUniqueId(review))
        call('office/testimonials/toggle-review-for-homepage', { json: review })
            .then((response) => setSelected(review, response.isSelected))
            .finally(() => setIsLoading(false))
    }

    return {
        countryCode,
        sourceIds,
        setSourceIds,
        reviews,
        selected,
        inviteInfos,
        isLoading,
        toggleReview,
    }
}

const HomePageTestimonialsPage = () => {
    const {
        countryCode,
        sourceIds,
        setSourceIds,
        reviews,
        selected,
        inviteInfos,
        isLoading,
        toggleReview,
    } = useHomepageTestimonials()

    return (
        <Page className={style.root}>
            <Divider l />
            <MaxWidth>
                <Row>
                    <Col xs={6}>
                        <Row>
                            <Col xs={6}>
                                <h3>
                                    {isLoading === true ? (
                                        <span>
                                            Fetching reviews <LoadingDots />
                                        </span>
                                    ) : (
                                        'Reviews'
                                    )}
                                </h3>
                            </Col>
                            <Col xs={6}>
                                <SelectCheckbox
                                    placeholder="Sources"
                                    options={[
                                        ...(countryCode === 'NL'
                                            ? [TESTIMONIAL_SOURCE_KLANTENVERTELLEN]
                                            : []),
                                        TESTIMONIAL_SOURCE_TRUSTPILOT,
                                    ].map((sourceId) => ({
                                        value: sourceId,
                                        name: (
                                            <Row middle="xs">
                                                <Col x>
                                                    <Icon
                                                        s3
                                                        s3Dir="icon"
                                                        name={
                                                            TESTIMONIAL_SOURCES[sourceId] + '_icon'
                                                        }
                                                    />
                                                </Col>
                                                <Col>{TESTIMONIAL_SOURCES[sourceId]}</Col>
                                            </Row>
                                        ),
                                    }))}
                                    value={sourceIds}
                                    onChangeValue={setSourceIds}
                                />
                            </Col>
                        </Row>
                        <div className={style.reviewList}>
                            {reviews.map((review) => {
                                const uniqueId = getUniqueId(review)
                                const isSaving = isLoading === uniqueId
                                const isSelected = selected[uniqueId]
                                const className = [
                                    style.review,
                                    isSaving ? style.loading : '',
                                    isSelected ? style.selected : '',
                                ].join(' ')

                                return (
                                    <div
                                        onClick={() => toggleReview(review)}
                                        className={className}
                                        key={uniqueId}>
                                        {isSaving && <LinearLoader absolute />}
                                        <Row className={style.nameRow} between="xs" middle="xs">
                                            <Col x>
                                                <Row middle="xs">
                                                    <Col x>
                                                        <Icon
                                                            s3
                                                            s3Dir="icon"
                                                            name={
                                                                TESTIMONIAL_SOURCES[
                                                                    review.testimonial_source_id
                                                                ] + '_icon'
                                                            }
                                                        />
                                                    </Col>
                                                    <Col x>{review.name}</Col>
                                                </Row>
                                            </Col>
                                            <Col x>
                                                <b>{review.rating}</b>
                                            </Col>
                                        </Row>
                                        <Divider s />
                                        <small>
                                            {formatDistanceToNow(getDate(review.created))}
                                            {review.updated &&
                                                review.updated !== review.created && (
                                                    <span>
                                                        , updated after{' '}
                                                        {formatDistance(
                                                            getDate(review.updated),
                                                            getDate(review.created)
                                                        )}
                                                    </span>
                                                )}
                                        </small>
                                        {review.title && (
                                            <div>
                                                <Divider m />
                                                <b>{review.title}</b>
                                            </div>
                                        )}
                                        <p className={style.body}>{review.body}</p>
                                        {review.comment && (
                                            <div className={style.comment}>{review.comment}</div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                    <Col xs={6}>
                        <h3>Review invites</h3>
                        <Divider l />
                        <Row>
                            <Col xs={6}>
                                <b>Platform</b>
                            </Col>
                            <Col xs={6}>
                                <b>Review invites sent</b>
                            </Col>
                        </Row>
                        {inviteInfos.map(({ name, invitesSent }) => (
                            <Fragment key={name}>
                                <Divider l name="border" />
                                <Row>
                                    <Col xs={6}>{name}</Col>
                                    <Col xs={6}>{invitesSent}</Col>
                                </Row>
                            </Fragment>
                        ))}
                    </Col>
                </Row>
            </MaxWidth>
        </Page>
    )
}

export default HomePageTestimonialsPage
