import React from 'react'
import { generateName, saveReview } from '../../actions/reviews'
import EntityComponentContainer from '../../containers/EntityComponentContainer'
import EntityComponent from '../services/EntityComponent'
import ReviewStars from './ReviewStars'
import { Col, Row } from '@advanza/grid'
import { Divider, Icon } from '@advanza/ui'

class ReviewEntity extends EntityComponent {
    constructor(props) {
        super(props)
        this.generateName = this.generateName.bind(this)
    }

    addCtlEnterSave = {
        onKeyDown: (event) => event.ctrlKey && event.key === 'Enter' && this.save(),
    }

    editFields() {
        const five = [2, 4, 6, 8, 10]
        return {
            name: { type: 'text', msg: 'Name', ...this.addCtlEnterSave },
            rating: {
                type: 'select',
                placeholder: 'Rating',
                className: 'select-stealth select-wide select-min',
                options: five.map((nr) => {
                    return {
                        value: nr,
                        title: <ReviewStars avg={nr / 2} />,
                    }
                }),
            },
            body: {
                type: 'textArea',
                msgid: 'Review body',
                style: {
                    backgroundColor: '#fff',
                    padding: 5,
                    border: '1px solid #b6b9cd',
                    borderRadius: 4,
                },
                ...this.addCtlEnterSave,
            },
            created: {
                type: 'date',
                dayPickerProps: {
                    disabled: (date) => date > new Date(),
                },
            },
            status: {
                type: 'select',
                className: 'select-min select-stealth',
                options: [
                    { title: 'Accepted', value: 1 },
                    { title: 'Deleted', value: 0 },
                    { title: 'Not validated', value: 3 },
                ],
            },
        }
    }

    generateName() {
        const obscurity = 99999999999999 // :D
        generateName({ obscurity: obscurity }).then(({ names }) => {
            this.onChangeEntity({ name: names[0] })
        })
    }

    render() {
        const { entity } = this.props

        return (
            <div>
                <Row>
                    <Col x>{this.renderInput('name')}</Col>
                    <Col x>
                        <button onClick={this.generateName}>
                            <Icon name="autorenew" />
                        </button>
                    </Col>

                    <Col x>{this.renderInput('rating')}</Col>
                </Row>

                {this.renderInput('body')}
                <Divider />

                <Row>
                    <Col x>{this.renderInput('status')}</Col>
                    <Col x>{this.renderInput('created')}</Col>
                </Row>

                <Row>
                    <Col x>
                        <button
                            onClick={this.save}
                            disabled={!entity._isTouched}
                            className="btn cta to-right">
                            {entity._saving ? 'saving..' : 'Save'}
                        </button>
                    </Col>
                </Row>
            </div>
        )
    }
}

ReviewEntity.propTypes = {}

export default EntityComponentContainer(ReviewEntity, {
    store: 'reviews',
    name: 'reviews',
    saveFunc: saveReview,
})
