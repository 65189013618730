import { Col, Row } from '@advanza/grid'
import { changeEntity } from '@advanza/redux_entity'
import { Button, Divider, LoadingDots } from '@advanza/ui'
import { saveRegionContent } from 'actions/regions'
import { useChangeEntity } from 'hooks/entityHooks'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const RegionEntity = ({ regionContentId, regionId }) => {
    const dispatch = useDispatch()
    const fields = {
        content: {
            type: 'text',
            textArea: true,
        },
    }
    const { entity, renderInput, onSaveEntity } = useChangeEntity(
        {
            store: 'regions',
            name: 'regionContent',
            entityId: regionContentId,
            saveFunc: saveRegionContent,
        },
        fields
    )

    // keep region_content_id and region_id the same, it looks cleaner and was always that way
    useEffect(() => {
        if (!regionContentId) {
            dispatch(
                changeEntity({
                    store: 'regions',
                    name: 'regionContent',
                    key: regionId,
                    diff: {
                        region_content_id: regionId,
                        region_id: regionId,
                        content: '',
                    },
                })
            )

            dispatch(
                changeEntity({
                    store: 'regions',
                    name: 'regions',
                    key: regionId,
                    diff: { regions_content: regionId },
                })
            )
        }
    }, [dispatch, regionContentId, regionId])

    return (
        <div>
            {renderInput('content')}
            <Divider />
            <Row end="xs">
                <Col x>
                    <Button disabled={entity._saving} onClick={onSaveEntity}>
                        {entity._saving ? <LoadingDots color="#fff" /> : 'Save'}
                    </Button>{' '}
                </Col>
            </Row>
        </div>
    )
}

export default RegionEntity
