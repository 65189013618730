import { Icon } from '@advanza/ui'
import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import * as ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet-async'
import { CSSTransition } from 'react-transition-group'
import { WaterMelonChatElementIds } from '../../misc'
import style from './../style.module.css'
import ManageBodyOverflow from './ManageBodyOverflow'

interface Props {
    afterExit?: () => void
    allowOverflow?: boolean
    backgroundColor?: string
    className?: string
    classNames?: string
    classObj?: Record<string, string>
    close?: () => void
    closeButtonPrefix?: ReactNode
    closeOnOutsideClick?: boolean
    disableCloseButton?: boolean
    local?: boolean
    maxWidth?: string | number
    modalInsideStyle?: CSSProperties
    open: boolean
    wrapContent?: boolean
    zIndex?: number
}

class Modal extends React.Component<PropsWithChildren<Props>, { mounted: boolean }> {
    private ref: HTMLDivElement | null | undefined
    constructor(props: Props) {
        super(props)
        this.onBodyClick = this.onBodyClick.bind(this)
        this.onEnter = this.onEnter.bind(this)
        this.onExit = this.onExit.bind(this)
        this.state = { mounted: false }
    }

    componentDidMount() {
        this.setState({ mounted: true })
    }

    onEnter() {
        const { closeOnOutsideClick } = this.props
        if (closeOnOutsideClick) {
            window.addEventListener('click', this.onBodyClick)
            window.addEventListener('touchend', this.onBodyClick)
        }
    }

    onExit() {
        const { closeOnOutsideClick, afterExit = () => {} } = this.props
        if (closeOnOutsideClick) {
            window.removeEventListener('click', this.onBodyClick)
            window.removeEventListener('touchend', this.onBodyClick)
        }
        afterExit()
    }

    onBodyClick(e: MouseEvent | TouchEvent) {
        const { open, close, closeOnOutsideClick, disableCloseButton = false } = this.props
        const containerNode = this.ref
        const target = e.target as HTMLElement
        if (
            !disableCloseButton &&
            closeOnOutsideClick &&
            containerNode &&
            target?.isConnected &&
            target !== containerNode &&
            !containerNode.contains(target) &&
            open &&
            close
        ) {
            close()
        }
    }

    canUseDOM() {
        return !!(typeof window !== 'undefined' && window.document && window.document.createElement)
    }

    render() {
        if (!this.canUseDOM() && !this.state.mounted) {
            return null
        }
        const {
            children,
            open,
            backgroundColor,
            zIndex,
            className = '',
            classNames = '',
            closeButtonPrefix = undefined,
            close,
            maxWidth,
            wrapContent = true,
            modalInsideStyle = {},
            classObj = {},
            local = false,
            allowOverflow = false,
            // eslint-disable-next-line no-unused-vars
            closeOnOutsideClick = false,
            // eslint-disable-next-line no-unused-vars
            afterExit = () => {},
            disableCloseButton = false,
            ...rest
        } = this.props

        const modal = (
            <>
                {open && ( // hide chat widget components when modal is open
                    <Helmet>
                        <style type="text/css">
                            {`
                                #${WaterMelonChatElementIds.WIDGET},
                                #${WaterMelonChatElementIds.CHAT_BUTTON_FIXED} { display: none !important; }
                            `}
                        </style>
                    </Helmet>
                )}
                <CSSTransition
                    in={open}
                    onEntered={this.onEnter}
                    onExited={this.onExit}
                    classNames={{
                        enter: style.in,
                        enterActive: style.inActive,
                        exitActive: style.outActive,
                        exit: style.out,
                    }}
                    timeout={300}
                    unmountOnExit>
                    <>
                        {!allowOverflow && <ManageBodyOverflow />}
                        <div
                            {...rest}
                            className={[
                                style.modal,
                                ...classNames.split(' ').map((name) => style[name]),
                                className,
                                local ? style.local : '',
                            ].join(' ')}
                            style={{ backgroundColor, zIndex }}
                            id="_modal">
                            <div
                                style={{ maxWidth, ...modalInsideStyle }}
                                ref={(ref) => (this.ref = ref)}
                                className={[style.modalInside, classObj.modalInside].join(' ')}>
                                {close && (
                                    <div
                                        className={[
                                            style.closeButton,
                                            closeButtonPrefix ? style.closeButtonWithPrefix : '',
                                            classObj.closeButton,
                                        ].join(' ')}>
                                        {closeButtonPrefix}
                                        <button
                                            disabled={disableCloseButton}
                                            onClick={() => close()}>
                                            <Icon name="close" fontSize={24} />
                                        </button>
                                    </div>
                                )}
                                {wrapContent ? (
                                    <div className={[style.content, classObj.content].join(' ')}>
                                        {children}
                                    </div>
                                ) : (
                                    children
                                )}
                            </div>
                        </div>
                    </>
                </CSSTransition>
            </>
        )

        return local ? modal : ReactDOM.createPortal(modal, document.getElementById('portal_1')!)
    }
}

export default Modal
