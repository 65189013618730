import Modal from '@advanza/modal'
import style from '@advanza/ui/baseModal.module.css'
import { useModal } from '@ebay/nice-modal-react'
import {
    ComponentPropsWithoutRef,
    createContext,
    CSSProperties,
    PropsWithChildren,
    useContext,
} from 'react'

export enum ScrollArea {
    /**
     *  the whole modal scroll
     */
    BODY = 'body',
    /**
     *  only the part in <BaseModalContent> scrolls
     */
    CONTENT = 'content',
}

interface Props {
    closeOnOutSideClick?: boolean
    modalInsideStyle?: CSSProperties
    mobileFullscreen?: boolean
    disableCloseButton?: boolean
    onClose?: () => void
    maxWidth?: number | string
    classNames?: string
    /**
     * within the modal, where does the scrollbar appears
     */
    scrollArea?: ScrollArea
}
const BaseModalContext = createContext<{ scrollArea: ScrollArea }>({ scrollArea: ScrollArea.BODY })

const NewBaseModal = ({
    children,
    classNames,
    closeOnOutSideClick = true,
    modalInsideStyle,
    mobileFullscreen = false,
    disableCloseButton = false,
    onClose: onCloseProp = undefined,
    scrollArea = ScrollArea.BODY,
    maxWidth,
}: PropsWithChildren<Props>) => {
    const modal = useModal()
    const onClose =
        onCloseProp ||
        (() => {
            modal.resolve()
            modal.hide()
        })

    return (
        <Modal
            classNames={classNames}
            maxWidth={maxWidth}
            className={mobileFullscreen ? style.mobileFullscreen : ''}
            classObj={{ modalInside: style.root, closeButton: style.closeButton }}
            wrapContent={false}
            open={modal.visible}
            close={onClose}
            closeOnOutsideClick={closeOnOutSideClick}
            afterExit={() => modal.remove()}
            modalInsideStyle={{
                ...modalInsideStyle,
                ...(scrollArea === ScrollArea.CONTENT && { maxHeight: '100%' }),
            }}
            disableCloseButton={disableCloseButton}>
            <BaseModalContext.Provider value={{ scrollArea }}>{children}</BaseModalContext.Provider>
        </Modal>
    )
}

export const BaseModalHeader = ({
    children,
    className,
    ...rest
}: ComponentPropsWithoutRef<'div'>) => (
    <div {...rest} className={[style.header, className].join(' ')}>
        {children}
    </div>
)

export const BaseModalFooter = ({
    children,
    className,
    ...rest
}: ComponentPropsWithoutRef<'div'>) => (
    <div {...rest} className={[style.footer, className].join(' ')}>
        {children}
    </div>
)

export const BaseModalMainContent = ({
    children,
    className,
    style: customStyle,
}: ComponentPropsWithoutRef<'div'>) => {
    const { scrollArea } = useContext(BaseModalContext)
    return (
        <div
            style={{
                ...(scrollArea === ScrollArea.CONTENT && { overflow: 'auto' }),
                ...customStyle,
            }}
            className={[style.content, className].join(' ')}>
            {children}
        </div>
    )
}

export default NewBaseModal
