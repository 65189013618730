import { HoverText, Icon } from '@advanza/ui'
import FormattedTime from 'components/ui/FormattedTime'
import { Match } from 'interfaces/Matches'
import style from './matchTimeOverview.module.scss'

interface Props {
    match: Match
}

const MatchTimeOverview = ({ match }: Props) => {
    const createInfoIcon = (text: string) => (
        <HoverText trigger={<Icon name="info" fontSize={16} />}>{text}</HoverText>
    )

    const formatTime = (dateTime: string) => (
        // @ts-ignore
        <FormattedTime date={dateTime} format="dd-MM-yyyy HH:mm" />
    )

    const createRow = (label: string, infoText: string, dateTime: string | null) => (
        <>
            <div>
                {label} {createInfoIcon(infoText)}
            </div>
            <div>{!!dateTime && formatTime(dateTime)}</div>
        </>
    )

    return (
        <div className={style.grid}>
            {createRow('Created', 'Match created time', match.created)}
            {createRow('Confirmed', 'Match added to supply dashboard', match.confirmed)}
            {createRow(
                'Is answered',
                'Pro answered demand (with an auto-reply or auto-quote or regular message) or there has been successful phone contact (indicated by pro or demand).',
                match.is_answered
            )}
            {createRow('Reclaimed', 'Pro reclaim accepted', match.reclaimed)}
            {createRow('Hired', 'Demand indicated the pro is hired', match.hired)}
            {createRow(
                'Last interaction',
                'Last interaction between supply and demand. Can be a pro message, demand message, pro phone view or demand phone view.',
                match.last_interaction
            )}
            <div className={style.divider}></div>
            {createRow(
                'Demand match visit',
                'First time demand viewed the match in the demand dashboard',
                match.is_visited
            )}
            {createRow(
                'Demand phone view',
                'First time demand viewed supply phone number',
                match.phone_view_demand
            )}
            <div className={style.divider}></div>
            {createRow(
                'Pro match visit',
                'First time supply viewed the match in the supply dashboard',
                match.is_read
            )}
            {createRow(
                'Pro phone view',
                'First time supply viewed the demand phone number',
                match.phone_view
            )}
            {createRow(
                'Pro clicked whatsapp',
                'First time supply clicked on the whatsapp button in the chat',
                match.pro_clicked_whatsapp
            )}
            <div className={style.divider}></div>
            {createRow(
                'Pro email view',
                'First time supply viewed the demand email',
                match.pro_email_viewed_log?.created || ''
            )}
            {createRow(
                'Pro email clicked',
                'First time supply click on "send email" or "copy email"',
                match.pro_email_clicked
            )}
        </div>
    )
}

export default MatchTimeOverview
