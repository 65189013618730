import {
    FieldInput,
    KeyPressHandler,
    Loading,
    Msg,
    Row,
    Section,
    WithIcon,
} from '@advanza/advanza_generic'
import { call } from '@advanza/api'
import { Button } from '@advanza/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { Redirect } from 'react-router-dom'
import isEmail from 'validator/es/lib/isEmail'

export const afterLoginShared = (response = {}, props = {}) => {
    const { user, redirect: serverRedirect } = response
    const { defaultRedirect: defaultRedirectFunc, basename, appName } = props
    const queryRedirect = new URLSearchParams(document.location.search).get('redirect')

    // Two-factor authentication
    if (!user && serverRedirect) {
        return serverRedirect + (queryRedirect ? `?redirect=${queryRedirect}` : '')
    }

    window._user = user

    const defaultRedirect = defaultRedirectFunc && defaultRedirectFunc(user)
    const isWrongApp =
        (appName === 'demand' && window._user.isPro) || (appName === 'pro' && !window._user.isPro)
    let redirect = queryRedirect || serverRedirect || defaultRedirect

    if (redirect.indexOf(basename) === 1) {
        redirect = redirect.substr(basename.length + 2)
    }

    if (redirect === '/' || redirect === '' || isWrongApp) {
        redirect = defaultRedirect
    }

    return redirect
}

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.validate = this.validate.bind(this)
        this.afterLoginError = this.afterLoginError.bind(this)
        this.afterLogin = this.afterLogin.bind(this)
        this.login = this.login.bind(this)

        this.state = {
            email: { value: props.email || '' },
            password: { value: '' },
            remember_me: { value: '1' },
            redirect: false,
        }
    }

    validate() {
        const { email, password } = this.state

        const emailValid = email.value && isEmail(email.value)
        const passwordValid = password.value.length > 5

        if (!emailValid) {
            this.setState({ email: { ...this.state.email, error: <Msg id="error.email" /> } })
        }
        if (!passwordValid) {
            this.setState({
                password: {
                    ...this.state.password,
                    error: <Msg id="form.error.password.alt" />,
                },
            })
        }

        return emailValid && passwordValid
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: { ...this.state[name], value, error: null } })
    }

    login() {
        if (!this.validate()) {
            return
        }
        const { email, password, remember_me } = this.state
        const request = {
            email: email.value,
            password: password.value,
            remember_me: remember_me.value,
        }
        this.setState({ loading: true })
        call('auth/login', {
            payload: request,
        }).then(this.afterLogin, this.afterLoginError)
    }

    afterLogin(response) {
        window.location.href = afterLoginShared(response, this.props)
    }

    afterLoginError() {
        this.setState({
            error: <Msg id="login.error.user_not_found" />,
            loading: false,
        })
    }

    render() {
        const { email, password, remember_me, loading, redirect, error } = this.state

        if (redirect) {
            return <Redirect to={redirect} />
        }

        if (window._user) {
            const { protocol, hostname } = window.location
            return (
                <>
                    You are already logged in as {window._user.email}. If you want to log in as a
                    different user, first{' '}
                    <a
                        style={{ color: '#5788ff' }}
                        href={`${protocol}//${hostname}/api/auth/logout`}>
                        log out.
                    </a>
                </>
            )
        }

        return (
            <div className={`pos-rel ${loading ? 'saving' : ''}`}>
                <Section s="plus" center>
                    <WithIcon
                        align="middle"
                        className="j-center"
                        icon={<img height={50} src={`https://static.trustoo.nl/logo_b_2x.png`} />}>
                        <h1>Trustoo office</h1>
                    </WithIcon>
                </Section>
                <Section>
                    <Section>
                        <FieldInput
                            name="email"
                            onChange={this.handleChange}
                            state={email}
                            autoFocus
                            icon="email"
                            type="email"
                            msgid="email"
                            newGStyle
                        />
                    </Section>
                    <Section n="min">
                        <FieldInput
                            name="password"
                            onChange={this.handleChange}
                            icon="lock"
                            state={password}
                            msgid="password"
                            type="password"
                            newGStyle
                        />
                    </Section>
                    {error && (
                        <Section className="c-red" y="min">
                            {error}
                        </Section>
                    )}
                    <Row n="min" nm className="justify checkbox v-center">
                        <label
                            className={
                                'row no-gutters v-center ' +
                                (remember_me.value === '1' ? 'checked' : '')
                            }>
                            <div className="option-icon" />
                            <Msg id="Remember me" />
                            <input
                                type="checkbox"
                                name="remember_me"
                                value={remember_me.value === '1' ? '0' : '1'}
                                onChange={this.handleChange}
                                checked={remember_me.value === '1'}
                            />
                        </label>
                        <a className="tright" href="https://trustoo.nl/login/reset-password/">
                            <Msg id="forgot password" />
                        </a>
                    </Row>
                </Section>
                <Section n>
                    <Button onClick={this.login} className="btn col-12">
                        Login
                    </Button>
                </Section>
                {loading && <Loading inside />}
                <KeyPressHandler onEnter={this.login} />
            </div>
        )
    }
}

Login.propTypes = {
    // function that returns the default redirect string with user param. defaultRedirect(user)
    defaultRedirect: PropTypes.func.isRequired,

    // basename of the app, this is used to strip it from a redirect.
    basename: PropTypes.string,
    appName: PropTypes.string,

    authorize: PropTypes.func,
}

export default Login
