import { call } from '@advanza/api'
import { _fetchItems } from 'actions/lists'
import regionsSchema from 'schemes/regionsSchema'

export function fetchRegions(filterId = 'all') {
    return _fetchItems(filterId, {
        url: 'office/regions/get-list',
        schema: regionsSchema,
        requestActionName: 'REGIONS_REQUEST',
        receiveActionName: 'REGIONS_RECEIVE_LIST',
        reducerKey: 'regions',
    })
}

export function saveRegionContent(regionContentId) {
    return function (dispatch, getState) {
        const data = getState().regions.entities.regionContent[regionContentId]
        return call('office/regions/save-content/' + regionContentId, {
            json: data,
        }).then(() => dispatch({ type: 'INVALIDATE_REGIONS' }), console.log)
    }
}
