export const PROFILE_BUILDER_REDIRECT = '/service/providers'

export const isProfileBuilder = () =>
    window._user &&
    (window._user.email.toLowerCase().indexOf('@prems.work') !== -1 ||
        window._user.username.toLowerCase().indexOf('@prems.work') !== -1)

export const hasAccess = (key = '', mode = 'r') => {
    return window._user.permissions[key] && window._user.permissions[key][mode]
}

export const isSuperUser = () => !!(window._user && window._user.is_superuser)
