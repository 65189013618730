import Card from 'components/Card'
import RegionEntity from 'components/regions/RegionEntity'
import TableList from 'components/TableList'
import { useRegionsList } from 'hooks/regionHooks'
import React from 'react'

const definitions = {
    region_id: { name: '#' },
    name: { name: 'name' },
    url_name: { name: 'url name' },
    shortregionname: { name: 'short name' },
    is_hidden: { name: 'is hidden' },
}

function renderRow(regionId, entities) {
    const { regions } = entities
    const region = regions[regionId]
    return {
        cols: Object.keys(definitions).map((key) => {
            if (key === 'is_hidden') {
                return region.is_hidden ? 'True' : 'False'
            }

            return region[key]
        }),
        expandable: (
            <Card expandableStyle>
                <RegionEntity
                    regionContentId={regions[regionId].regions_content}
                    regionId={regionId}
                />
            </Card>
        ),
    }
}

const RegionsList = ({ filterId = 'all' }) => {
    const list = useRegionsList(filterId)
    const rows = {}
    list.currentPage.forEach((regionId) => {
        rows[regionId] = renderRow(regionId, list.entities)
    })
    return <TableList definitions={definitions} {...list} rows={rows} />
}

export default RegionsList
